/**
 * This function filters full list of aircraft and returns a filtered list based on a category
 * @author Andrew Bedziak
 * 
 * @param {allAircraftSpecs} array - Array of all aircraft
 * @param {allAircraft} array - Array of alternate languages of each page
 * @param {category} string - Language of the current page. 
 * @param {location} object - Location object

 * @returns {array} 
 */

import { createLocalisedAircraftCategories } from 'utils/functions/aircraft/createLocalisedAircraftCategories'

export const filterAircraftData = (allAircraftSpecs, allAircraft, category) => {
  let aircraftFilteredByCategory = [];
  let getLength = [];

  const enCategory = createLocalisedAircraftCategories(category);

  if (allAircraft) {
    allAircraft.filter(el => {
      return allAircraftSpecs.filter(elem => {
        if (elem.node.specs_list.id === Number(el.node.data.aircraft_id) ) {
          getLength.push({
            category: elem.node.specs_list.title,
            id: el.node.data.aircraft_id
          })
        }
      })
    })

    aircraftFilteredByCategory = getLength.filter(el => el.category === enCategory);

    return aircraftFilteredByCategory
  }
}