import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import SEO from "SEO";
import { connect } from 'react-redux';

import Layout from "components/layout";
import Banner from 'components/banner';

import RenderRichText from 'utils/richText/renderRichText';

import { setPhoneNumber } from 'state/actions/phoneNumber';

const AircraftMainWrapper = ({
  location,
  bannerImageSlice,
  language,
  alternateLanguages,
  localePhoneNumber,
  universalProps,
  errorMessages,
  footerProps,
  headerData,
  preview,
  uid,
  meta,
  languageCodes,
  component,
  phoneNumber,
  setPhoneNumber
}) => {

  useEffect(() => {
    setPhoneNumber(phoneNumber)
  }, [])

  const bannerImgSlicePrimary = bannerImageSlice?.primary;
  const bannerImageSrc = bannerImgSlicePrimary?.image?.url;
  const textPosition = bannerImgSlicePrimary?.text_position;
  const headingOneRichText = preview ? bannerImgSlicePrimary?.heading1 : bannerImgSlicePrimary?.heading1?.raw; 
  const bannerImageSize = bannerImgSlicePrimary?.banner_image_size;

  const layoutProps = {
    location,
    language,
    alternateLanguages,
    localePhoneNumber,
    universalProps,
    errorMessages,
    icons: universalProps,
    footerProps,
    headerData,
    preview,
    uid,
    previewHeaderData: headerData,
    previewFooterData: footerProps.node,
  }

  const seoProps = { meta, location, language, alternateLanguages, languageCodes, preview }

  return (
    <Layout {...layoutProps}>
      <React.Fragment>
        
        <SEO {...seoProps} />
        
        <Banner 
          desktopLarge={bannerImageSize === "large" ? true: false}
          desktopMedium={bannerImageSize === "medium" ? true: false}
          desktopSmall={bannerImageSize === "small" ? true: false}
          headingOne={headingOneRichText && <RenderRichText richTextArray={headingOneRichText} />}
          isHomePage={false}
          textPosition={textPosition}
          bannerImageSrc={bannerImageSrc}
        />

        { component }
      
      </React.Fragment>
    </Layout>
  );
};

// export default AircraftMainWrapper;
const mapDispatchToProps = dispatch => ({
  setPhoneNumber: phoneNumber => dispatch(setPhoneNumber(phoneNumber))
})

export default connect(null, mapDispatchToProps)(AircraftMainWrapper);

AircraftMainWrapper.propTypes = { 
  alternateLanguages: PropTypes.array,
  bannerImageSlice: PropTypes.object,
  component: PropTypes.object,
  errorMessages: PropTypes.array,
  footerProps: PropTypes.object,
  headerData: PropTypes.object,
  language: PropTypes.string,
  languageCodes: PropTypes.array, 
  localePhoneNumber: PropTypes.array,
  location: PropTypes.object,
  meta: PropTypes.object,
  phoneNumber: PropTypes.string,
  preview: PropTypes.bool,
  setPhoneNumber: PropTypes.func,
  uid: PropTypes.string,
  universalProps: PropTypes.array,
};
