import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderRichText from 'utils/richText/renderRichText';
import AircraftList from 'components/aircraftListing/aircraftHome/AircraftList';

import { AircraftIndexSummaryWrapper, StyledAircraftHomeWrapper } from 'components/aircraftListing/styled'

import { FullWidthContainer } from 'components/grid/styled';

import { toggleButton } from 'state/actions/aircraftFilter';

class AircraftHome extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //toggle filterbar colour button for aircraft top level page
    this.props.toggleButton('all');
  }

  render() {
    const {
      allAircraft,
      aircraftCategories,
      aircraftHomeSummary,
      aircraftHomeUrl,
      aircraftCardProperties,
      categoryDescription,
      preview,
      language,
    } = this.props;

    const overviewSectionText = preview ? aircraftHomeSummary.summary : aircraftHomeSummary.summary.raw;

    return (
      <React.Fragment>
        <StyledAircraftHomeWrapper>
          <FullWidthContainer>
            <AircraftIndexSummaryWrapper 
              isIndexPageTopParagraph={true} 
              style={{border: "none", paddingTop: "0"}}
            >
              <RenderRichText richTextArray={overviewSectionText} />
            </AircraftIndexSummaryWrapper>
          </FullWidthContainer>
          <AircraftList
            allAircraft={allAircraft}
            aircraftHomeUrl={aircraftHomeUrl}
            aircraftCardProperties={aircraftCardProperties}
            categoryDescription={categoryDescription}
            aircraftCategories={aircraftCategories}
            preview={preview}
            language={language}
          />
        </StyledAircraftHomeWrapper>
      </React.Fragment>
    );
  }
};

const mapDispatchToProps = dispatch => ({
  toggleButton: isActive => dispatch(toggleButton(isActive))
});

export default connect(null, mapDispatchToProps)(AircraftHome)

AircraftHome.propTypes = {
  aircraftCardProperties: PropTypes.array,
  aircraftCategories: PropTypes.array,
  aircraftHomeSummary: PropTypes.object,
  aircraftHomeUrl: PropTypes.string,
  allAircraft: PropTypes.array,
  categoryDescription: PropTypes.array,
  language: PropTypes.string,
  preview: PropTypes.bool,
  toggleButton: PropTypes.func
};
