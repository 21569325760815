import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import AircraftHome from 'components/aircraftListing/aircraftHome';
import AircraftMainWrapper from 'components/HOC/AircraftMainWrapper';

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const AircraftIndexPage = ({ data, location, preview, pageContext }) => {
  let categories = [];

  const language = preview ? data.prismicAircraftHome.lang : pageContext.lang;
  const alternateLanguages = preview ? data.prismicAircraftHome.alternate_languages : pageContext.alternateLanguages;

  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;
  
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const universalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);

  //language codes from header query for SEO component
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);

  //array for navigation to filter aircraft categories
  const aircraftCategories = data.prismicAircraftHome.data.aircraft_category.map(el => {
    const category = preview ? el.data.aircraft_category2 : el.category.document[0].data.aircraft_category2;
    const categoryUrl = preview ? el.data.url : el.category.document[0].data.url;
  
    return {
      category: category,
      url: categoryUrl
    }
  })

  let headData = {
    meta_title: data.prismicAircraftHome.data.title,
    meta_description: data.prismicAircraftHome.data.meta_description,
    meta_keywords: data.prismicAircraftHome.data.meta_keywords,
  }
  
  if (!preview) {
    data.prismicAircraftHome.data.aircraft_category.map(el => {
      categories.push(el.category.document[0].data.aircraft_category2)
    })
  }

  const allAircraft = data.allPrismicAircraft.edges.filter(el => el.node.lang === language);
  const categoriesDescription = data.prismicAircraftHome.data.aircraft_category;
  const bannerImageSlice = data.prismicAircraftHome.data.body.find(el => el.slice_type === "banner_image");

  // add open graph meta data if available
  if(data?.prismicAircraftHome?.data?.body2?.length > 0) {
    const filteredOpenGraphData = data.prismicAircraftHome.data.body2.filter(el => el.slice_type === "open_graph1");

    if (filteredOpenGraphData?.length > 0) {
      const openGraphData = filteredOpenGraphData[0]?.primary;

      if (openGraphData?.og_title) {
        headData = {
          ...headData,
          og_title: openGraphData.og_title,
          og_description: openGraphData?.og_description || openGraphData?.og_title,
          og_image: openGraphData?.og_image?.url.split('?')[0] || '',
          og_type: openGraphData?.og_type || 'website',
        }
      }
    }
  }

  return (
    <AircraftMainWrapper 
      meta={headData}
      location={location}
      language={language}
      alternateLanguages={alternateLanguages}
      languageCodes={languageCodes}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      footerProps={footerProps}
      universalProps={universalProps}
      headerData={headerData}
      preview={preview}
      bannerImageSlice={bannerImageSlice}
      component={
        <AircraftHome
          allAircraft={allAircraft}
          aircraftHomeSummary={data.prismicAircraftHome.data}
          aircraftCategories={aircraftCategories}
          categoryDescription={categoriesDescription}
          aircraftHomeUrl={data.prismicAircraftHome.data.url}
          preview={preview}
          language={language}
          alternateLanguages={alternateLanguages}
        />
      }
    />
  );
};

export default AircraftIndexPage;

AircraftIndexPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
};

export const query = graphql`
  query AircraftIndexQuery($id: String!) {
    prismicAircraftHome(id: { eq: $id }) {
      ...PrismicAircraftHome
    }
    allPrismicAircraft {
      edges {
        node {
          lang
          data {
            aircraft_category
            aircraft_id
            url
          }
        }
      }
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allAircraftSpecs {
      edges {
        node {
          ...AircraftSpecs
        }
      }
    }
    allPrismicAircraftproperties {
      edges {
        node {
          ...PrismicAircraftproperties
        }
      }
    }
  }
`;
