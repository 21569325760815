import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby";

import Card from 'components/card';
import ArrowButton from 'components/buttons/ArrowButton';

import { FullWidthCardContainer } from 'components/grid/styled'; 

import { filterAircraftData } from 'utils/functions/aircraft/filterAircraftData';

const AircraftList = ({ categoryDescription, allAircraft, preview, language }) => {

  const data = useStaticQuery(graphql`
    query AircraftMainListQuery {
      allAircraftSpecs {
        edges {
          node {
            ...AircraftSpecs
          }
        }
      }
    }
  `)

  const isFR = process.env.LANG_CODE==='fr-fr' || language==='fr-fr';
  const allAircraftSpecs = data.allAircraftSpecs.edges

  return (
    <FullWidthCardContainer marginBottom="40px">
      {categoryDescription.map((el, index) => {
        const categoryUrl = preview ? el.data.url : `${el.category.document[0].data.url}`;
        const category = preview ? el.data.aircraft_category2 : el.category.document[0].data.aircraft_category2;
        const filteredAircraftListByCategory = filterAircraftData(allAircraftSpecs, allAircraft, category);
        const cardDescription = preview ? el.data.card_desription[0].text : el.category.document[0].data.card_desription.text;
        const cardImageUrl = preview ? el.data.card_image.url : el.category.document[0].data.card_image.url;

        return (
          <Card 
            buttonComponent={
              <ArrowButton 
                isLink={true}
                theme="red"
                buttonText={`${isFR ? "VOIR LES" : "SEE ALL"} ${filteredAircraftListByCategory.length} ${category}`} 
                buttonHref={categoryUrl}
                language={language}
              />
            }
            buttonAlign="left"
            textAlign="left"
            isCardLink={true}
            language={language}
            cardLink={categoryUrl}
            cardText={<p>{cardDescription}</p>}
            headingText={<h2>{category}</h2>}
            cardImageUrl={cardImageUrl && cardImageUrl}
            hasSeperator={false}
            textMarginBotttom={"50px"}
            lastCardMargin="0"
          />
        );
      })}
    </FullWidthCardContainer>
  );
};

export default AircraftList;

AircraftList.propTypes = {
  allAircraft: PropTypes.array,
  categoryDescription: PropTypes.array,
  language: PropTypes.string,
  preview: PropTypes.bool
};