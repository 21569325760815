import styled, { css } from 'styled-components';

export const StyledAircraftHomeWrapper = styled.div`
  margin-top: 30px;
  overflow-x: hidden;

  @media(min-width: 1024px) {
    margin-top: 60px;
  }
`
export const AircraftWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: column;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const AircraftPreviewImg = styled.img`
  &&&&&&& {
    max-height: 165px;
    width: 350px !important;

    &:hover {
        transform: scale(1) !important;
    }
  }
`;

export const StyledFilterBar = styled.div`
  display: none;
  width: 100%;
  margin-bottom: 15px;
  box-shadow: 0 0 12px 0 rgba(72,79,93,0.17);

  @media(min-width: 1024px) {
    margin-bottom: 55px;
    margin-top: ${props => props.isAircraftCategoryPage ? "55px" : "0"};
  }

  @media(min-width: 1200px) {
    display: flex;
  }
`

export const StyledFilterAircraftCard = styled.div`
  display: flex;
  position: relative;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: #000000;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  font-family: 'Raleway', sans-serif !important;
  text-transform: uppercase;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : "white"};
  transition: 0.7s;


  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    background: transparent;
    font-family: "Open Sans";
  }

  a:hover {
    color: #000000;
    background: transparent;
    text-decoration: none;
  }

  &:nth-of-type(1) {
    width: 100px;
    padding-left: 0;
    padding-right: 0;
  }

  &:hover {
    cursor: pointer;
    background: ${props => (props.isActive === props.title ? '#dedede' : '#dedede')};
  }

  ${props => 
    props.activeTab === true &&
      css`
        &::after {
          content: "";
          position: absolute;
          bottom: -7.5px;
          height: 15px;
          width: 15px;
          background: #D4D6DE;
          transform: rotate(45deg);
        }
      `
  }
`;

export const StyledSummaryWrapper = styled.div`
  width: 100%;
  margin-top: 0;

  h2 {
    border-top: 2px solid #dddddd;
    margin-top: 0;
  }
  @media (min-width: 1200px) {
    margin-top: 0;
    border: none;
    width: 60%;
  }
`

export const AircraftIndexSummaryWrapper = styled.div`
  width: 100%;
  margin-top: 0;
  margin-bottom: 30px;
  padding-top: 30px;

  p {
    margin-bottom: 0;
    color: #636463;
    font-size: ${props => props.isIndexPageTopParagraph ? "21px" : "14px" };
    line-height: ${props => props.isIndexPageTopParagraph ? "initial" : "16px"};
  }

  h2 {
    color: #3C3C3B;
    font-size: 18px;
    font-family: Raleway;
    font-weight: 500;
    margin-top: 0;

    @media (min-width: 768px) {
      font-size: 24px;
      margin-top: initial;
    }
  }

  @media (min-width: 1024px) {
    margin-bottom: 55px;
    padding-top: initial;
    border-top: none;
  }
  
  @media (min-width: 1200px) {
    margin-top: 0;
    border: none;
    width: 100%;
    
    p {
      width: 60%;
    }
  }
`