/**
 * This function creates an English aircraft category based on a French version
 * @author Andrew Bedziak
 * 
 * @param {aircraftCategory} string - Language of the current page. 

 * @returns {string} 
 */

export const createLocalisedAircraftCategories = (aircraftCategory) => {
  switch (aircraftCategory) {
    case "Turbopropulseur" :
      return "Turboprop"
    case "Jets Légers" : 
      return "Light Jet"
    case "Jet intermédiaire / medium": 
      return "Midsize Jet"
    case "Jets long-courriers": 
      return "Super Midsize Jet"
    case "Jet lourd": 
      return "Heavy Jet"
    case "Avions commerciaux": 
      return "VIP Airliner"
    default:
      return aircraftCategory
  }
}